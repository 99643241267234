<i18n locale="en" lang="yaml">
page.title : "Login"
login.title : "Login to @:common.application.title"
username : "Username"
username.placeholder : "Please input username"
password : "Password"
password.placeholder : "Password"
password.err.pattern : "@:common.password.pattern"
loginbtn : "Login"
forgot_password: "Forget Password ?"
line_login.btn : "Login with LINE"
</i18n>
<i18n locale="th" lang="yaml">
page.title : "เข้าสู่ระบบ"
login.title : "เข้าสู่ระบบ @:common.application.title"
username : "ชื่อผู้ใช้งาน"
username.placeholder : "ระบุชื่อผู้ใช้งาน"
password : "รหัสผ่าน"
password.placeholder : "รหัสผ่าน"
password.err.pattern : "@:common.password.pattern"
loginbtn : "เข้าสู่ระบบ"
forgot_password: "ลืมรหัสผ่าน ?"
line_login.btn : "ล็อกอินด้วย LINE"
</i18n>

<template>
	<div id="login_page" class="page-login">
		<div class="login-title">
			<!-- <img
				class="img-logo"
				src="@assets/images/logo_tg_128.png"/> -->
			<span id="login_title_text">{{$t('common.application.title')}}</span>
		</div>
		<div class="login-title-second">
			<span id="login_title_second_text">{{$t('common.application.title.second')}}</span>
		</div>

		<div class="login-box">
			<div class="content">
				<a-form
					layout="vertical"
					:form="formObj"
					@submit.prevent="handleSubmit">

					<a-form-item :label="$t('username')">
						<a-input
							v-decorator="[
								'username' ,
								{ rules: [
									{ required: true,whitespace: true, message: $tt('validate.required','username') },
								] },
							]"
							size="large"
							type="text"
							:placeholder="$t('username.placeholder')">
							<a-icon
								slot="prefix"
								type="user"/>
						</a-input>
					</a-form-item>

					<a-form-item :label="$t('password')">
						<a-input
							v-decorator="[
								'password' ,
								{ rules: [
									{ required: true, message: $tt('validate.required','password') },
									{ pattern: /^(?=.*[a-zA-Z#$^+=!*()@%&])(?=.*\d).{6,40}$/ ,message: $t('password.err.pattern') },
								] },
							]"
							size="large"
							type="password"
							:placeholder="$t('password.placeholder')">
							<ion-icon slot="prefix" name="key-outline" class="anticon" />
						</a-input>
					</a-form-item>
					<div class="row-forgot">
						<my-router-link
							id="login_forgot_password_link"
							name='forgot-password'
							no-param
							class="link-forgot">
							{{$t('forgot_password')}}
						</my-router-link>
					</div>

					<a-button
						id="login_submit_button"
						type="primary"
						:loading="formLoading"
						size="large"
						html-type="submit"
						block>
						{{$t('loginbtn')}}
					</a-button>
					<div class="row-or">
						{{$t('common.or')}}
					</div>
					<a-button id="login_linelogin_button" :loading="formLoading" size="large" block class="line-login-btn" @click="handleLineLogin">
						<img src="@assets/images/logo_line_login.png" class="logo">
						<span class="label">
							{{$t('line_login.btn')}}
						</span>
					</a-button>

				</a-form>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import HasAntdFormMixin from '@mixins/HasAntdFormMixin.vue'
import {basicAxios} from '@utils/axiosUtil.js'
import ApiError from '@utils/errors/ApiError'
import axios from 'axios'

export default {
	page() {
		return {
			title: this.$t('page.title'),
		}
	},
	mixins: [HasAntdFormMixin],
	methods: {
		...mapActions('auth', ['login']),
		...mapGetters('app',['changeDefaultPathByDevice']),
		handleSubmit(e) {
			this.formObj.validateFields((err, values) => {
				if (!err) {
					this.formLoading = true
					this.login({
						username: values.username,
						password: values.password,
					}).then(() => {
							axios.get("/api/users/consent")
							.then((response) =>
							response.data.data.isConsent ?
							this.$router.push({name: this.changeDefaultPathByDevice()}) : this.$router.push({name: 'consent'}))
					}).catch((error) => {
							this.formSubmitErrors(error)
					}).finally(() => {
							this.formLoading = false
					})
				} else {
					this.displayValidateErrorMessage()
				}
			})
		},
		handleLineLogin() {
			this.formLoading = true
			basicAxios.get("/api/auth/line-login-url",{params:{mode:'login'}}).then((response)=>{
				const url = response.data.data.url
				window.location = url
			}).catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error))
			}).finally(()=>{
				this.formLoading = false
			})
		}
	},
}
</script>

<style lang="less" scoped>
.img-logo {
	width: 72px;
	margin-right: 8px;
}
.login-title {
	font-family: @font-family-title;
	font-size: 2.5em;
	font-weight: 600;
	color: @tigerdrone-logo-color;
	margin: 32px 0;

	.mobile & {
		font-size: 1.5em;
	}
}
.login-title-second {
	font-family: @font-family-title;
	font-size: 1.2em;
	font-weight: 600;
	color: @tigerdrone-logo-color;
	margin: 32px 0 24px;

	.mobile & {
		font-size: 1em;
	}
}
.login-box {
	margin: 0 auto;
	max-width: 400px;
	border-radius: 16px;
	background-color: @white;
	box-shadow: @shadow-2;
	.content {
		padding: 32px 32px 24px;
		text-align: left;

		.mobile & {
			padding : 24px 16px 16px;
		}
	}
}
.row-forgot {
	margin-top : -12px;
	margin-bottom : 4px;
	text-align : right;
}
.link-forgot {
	font-size : 0.9em;
	color : @primary-4;
}
.row-or {
	text-align: center;
	margin-top : 16px;
	margin-bottom: 16px;
	color : @text-muted;
}
.ant-btn.line-login-btn {
	background-color : #00C300;
	color : @white;
	border : none;
	padding : 0;
	position: relative;
	line-height: 40px;
	&:hover,&:focus {
		background-color: #00c900;
		.logo {
			border-right-color : #00e000;
		}
	}

	.logo {
		position : absolute;
		width : 40px;
		height : 40px;
		padding : 4px;
		top : 0;
		left : 4px;
		border-right : 1px solid #00b300;
	}
	.label {
		font-family: Helvetica, Arial,sans-serif;
		padding-left : 44px;
	}
	.mobile & {
		line-height: 32px;
		.logo {
			width : 32px;
			height : 32px;
			left : 2px;
		}
		.label {
			padding-left : 34px;
		}
	}

}
</style>
